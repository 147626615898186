import React from 'react';

import { useQuery } from 'react-apollo';
import AcademyQuery from './AcademyQuery.gql';

import { styled } from "linaria/react";

import { theme } from "../Theme";
// import Head from '@jetshop/core/components/Head';

// import AcademySidebar from './AcademySidebar';
// import AcademyGrid from './AcademyGrid';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image';
import logo from '../../img/academy-logo.jpg';
import t from 'format-message';

export const AcademyContainer = styled(MaxWidth)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 4rem;
  aside {
    width: 20%;
  }
  > ul {
    margin-left: 2rem;
    width: calc(80% - 2rem);
  }

  ${theme.below.md} {
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 4rem;
    aside {
      width: 100%;
    }
    > ul {
      margin-left: 0;
      width: 100%;
    }
  }
`;

// Maintenance page styled components
const MaintenanceContainer = styled('div')`
  background-color: ${theme.colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 15rem 0;
  text-align: center;
`;

const Logo = styled('div')`
  margin-bottom: 2rem;
  width: 200px;

  ${theme.below.md} {
    width: 150px;
  }
`;

const Message = styled('p')`
  max-width: 500px;
  line-height: 1.5;
  margin: 0 auto;
  font-size: 1.2rem;

  ${theme.below.md} {
    font-size: 1rem;
  }
`;

const AcademyPage = ({ location: { pathname }, match: { path }, ...props }) => {
  // const { data, loading, error } = useQuery(AcademyQuery, {
  //   variables: {
  //     pathname
  //   }
  // });

  // Temporarily return maintenance page while content is being restructured
  return (
    <MaintenanceContainer>
      <Logo>
        <Image src={logo} alt="Hööks Academy" />
      </Logo>
      <Message>
        {t('We are remodeling our Hööks Academy pages to give you an even better experience. The new and improved Academy will launch shortly.')}
      </Message>
    </MaintenanceContainer>
  );

  /* Original component code commented out during content restructuring
  if (error) return null;
  if (loading) return null;
  const {
    route: { object }
  } = data;
  const category = object;
  var posts = [];
  if (path === pathname) {
    for (let i = 0; i < category.subcategories.length; i++) {
      const subCat = category.subcategories[i];
      posts = posts.concat(subCat.subcategories);
    }
  } else {
    posts = category.subcategories;
  }

  return (
    <AcademyContainer>
      <Head data={category.head} />
      <AcademySidebar path={path} pathname={pathname} />

      <AcademyGrid posts={posts} />
    </AcademyContainer>
  );
  */
};

export default AcademyPage;
